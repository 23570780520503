import { images } from 'assets'

const FooterCopy = () => {
  return (
    <div className='footer-copy'>
      <div className='flex social-links' role='navigation' aria-label='Social Media Links'>
        <a
          href='https://www.instagram.com/linqtoplatform/'
          target='_blank'
          rel='noreferrer'
          aria-label='Visit Linqto on Instagram'
        >
          <img alt='Linqto Instagram' src={images.footer_instagram}></img>
        </a>
        <a
          href='https://www.facebook.com/Linqto'
          target='_blank'
          rel='noreferrer'
          aria-label='Visit Linqto on Facebook'
        >
          <img alt='Linqto Facebook' src={images.footer_facebook}></img>
        </a>
        <a
          href='https://www.linkedin.com/company/linqto-inc./'
          target='_blank'
          rel='noreferrer'
          aria-label='Visit Linqto on LinkedIn'
        >
          <img alt='Linqto Linkedin' src={images.footer_linkedin}></img>
        </a>
        <a
          href='https://www.youtube.com/channel/UCS8uFXbni3A-4FhwqNqpFBQ'
          target='_blank'
          rel='noreferrer'
          aria-label='Visit Linqto on YouTube'
        >
          <img alt='Linqto Youtube' src={images.footer_youtube}></img>
        </a>
        <a
          href='https://twitter.com/linqtoinc?lang=en'
          target='_blank'
          rel='noreferrer'
          aria-label='Visit Linqto on X'
        >
          <img alt='Linqto X' src={images.footer_X}></img>
        </a>
      </div>
      <div className='copyright b_14_regular'>
        <div data-testid='copyrightCopy'>&#169; {new Date().getFullYear()} LINQTO Inc.</div>
        <div className='margined'>
          IMPORTANT LEGAL NOTICE AND DISCLOSURES:
          <br/>
          Informational use only. Not intended for reproduction, copying or
          distribution without expressed written consent of Linqto, Inc. et al.
          Not intended to provide investment advice nor does it constitute a
          solicitation or an offer to buy or sell any security or other
          financial instrument. Nothing contained in this website constitutes
          tax, legal, insurance or investment advice. Any use, interference
          with, disclosure or copying of this material is unauthorized and
          strictly prohibited. Investing in securities in private companies is
          speculative and involves a high degree of risk. The recipient must be
          prepared to withstand a total loss of your investment. We strongly
          encourage the recipient to complete their own independent due
          diligence before investing in securities or financial instruments
          including obtaining additional information, opinions, financial
          projections and legal or other investment advice. Linqto Inc., along with the products, services, and securities of its affiliated entities, including Linqto Liquidshares LLC, are not available to Maryland residents.
          <div className='margined'>
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://policies.google.com/privacy'
              className='copyright-link'
              aria-label='Google Privacy Policy'
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href='https://policies.google.com/terms'
              rel='noopener noreferrer'
              target='_blank'
              className='copyright-link'
              aria-label='Google Terms of Service'
            >
              Terms of Service
            </a>{' '}
            apply.
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterCopy
