import { Button, Checkbox, Tooltip, Modal, ConditionalRender } from 'components'
import {
  formatDecimal,
  formatWholeNumber,
  renderTotalsOrDash,
  getDomain,
  Mixpanel
} from 'utils'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { images } from 'assets'
import Toggle from 'components/Global/Toggle'
import CustomSelect from 'components/Global/Inputs/CustomSelect'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { Controller, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

const SimplifiedPurchase = ({
  purchaseWithShares,
  selectedAccount,
  selectedOptions,
  setSelectedOptions,
  toggleShares,
  toggleCash,
  purchaseWithCash,
  promoCode,
  promoCodeError,
  removePromoCode,
  onPromoCodeChange,
  toggleLinqtoBucks,
  linqtoBucksEnabled,
  togglePurchaseCredits,
  purchaseCreditsEnabled,
  refreshOrder,
  handlePurchaseAsChange,
  handleCommitOrder,
  updateOrder,
  setPurchaseWithShares,
  tradeButtonClicked,
  setTradeButtonClicked
}) => {
  const {
    order,
    company,
    linqtoBucks,
    confirmOrderLoading,
    totalPurchaseCredits,
    maxOrderLinqtoBucksPercent,
    accounts,
    documents
  } = useSelector((state) => state.placeOrderSlice)
  const {
    featureFlags: { PurchaseCreditEnabled, PurchaseWithSharesAllowed }
  } = useSelector((state) => state.userSlice)
  const {
    sharePrice,
    total,
    shares,
    amount,
    sellAmount,
    isPromoCodeValid,
    linqtoBucksUsed,
    creditsUsed,
    discountAmount,
    discountPercent,
    commitLoading
  } = order || {}

  const history = useHistory()
  const [showHover, setShowHover] = useState(false)
  const [showPurchaseCreditTooltip, setShowPurchaseCreditTooltip] =
    useState(false)
  const [showTransactionFeeTooltip, setShowTransactionFeeTooltip] =
    useState(false)
  const [modalType, setModalType] = useState(null)
  const [showSharesModalError, setShowSharesModalError] = useState(false)
  const [entity, setEntity] = useState(selectedAccount)

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useFormContext()
  const cashBalance = selectedAccount?.amountAvailable
  const disabled =
    commitLoading ||
    Object.keys(errors).length > 0 ||
    total > cashBalance ||
    (!purchaseWithShares && !purchaseWithCash)
  const confirmOrderCheckbox = watch('confirmOrderCheckbox')
  const isFormValid = Object.keys(errors).length === 0
  const accountLabel = getValues('sellCompanyId')
    ? JSON.parse(getValues('sellCompanyId'))?.label
    : null
  const sellCompanies =
    selectedAccount?.sellCompanies?.map((s) => ({
      value: s.companyId,
      label: s.name,
      icon: s.iconUrl,
      numberOfShares: s.numberOfshares,
      pricePerShare: s.pricePerShare,
      customLabel: `${formatDecimal(s.totalSharePrice)} (${formatWholeNumber(
        s.numberOfshares
      )} Shares)`
    })) || []

  useEffect(() => {
    if (tradeButtonClicked && sellCompanies?.length && order?.sellCompanyId) {
      setPurchaseWithShares(true)
      updateSharesDropdown(sellCompanies[0])
      setModalType('payment')
      setTradeButtonClicked(false)
    }
  }, [tradeButtonClicked, sellCompanies, order?.sellCompanyId])

  const updateDiscounts = async () => {
    if (promoCode?.length && !isPromoCodeValid) {
      updateOrder()
    } else if (!promoCodeError) {
      updateOrder()
      setModalType(null)
    }
  }

  const updateSharesDropdown = (option) => {
    setValue('simpleCompanyId', JSON.stringify(option))
    setSelectedOptions([option])
    refreshOrder(false, linqtoBucksEnabled, promoCode, false)
  }

  const updatePaymentMethod = () => {
    if (purchaseWithShares && selectedOptions?.length === 0) {
      setShowSharesModalError(true)
      return
    }
    setValue('sellCompanyId', getValues('simpleCompanyId'))
    setValue('simpleCompanyId', null)
    setShowSharesModalError(false)
    setModalType(null)
  }

  const updateEntity = () => {
    handlePurchaseAsChange(entity)
    setModalType(null)
  }

  const trackPlaceOrder = () => {
    Mixpanel.track('Click Place Order Button', {
      Amount: total,
      'Cash Balance': cashBalance,
      'purchase-simplification': true
    })
  }

  const goToAddFunds = () => {
    Mixpanel.track('Click Add Funds on Buy Order Page', {
      'purchase-simplification': true
    })
    // Send the required amount to the add funds page and set buy page path in local storage
    const amount = formatDecimal(total - cashBalance, true, 2, true)

    localStorage?.setItem(
      'from-buy-order-page',
      location.pathname + location.search
    )
    history.push(
      `/cash-account/add-funds?amountRequired=${amount}${
        selectedAccount?.entityId
          ? `&entityId=${selectedAccount?.entityId}`
          : ''
      }`
    )
  }

  const handleViewDocument = (name) => {
    Mixpanel.track('View Document on Buy Order Review Page', {
      'Document Type': name
    })
  }

  const handleCloseModal = () => {
    // Turn off and reset shares if user closes modal
    if (modalType === 'payment' && purchaseWithShares) {
      setValue('sellCompanyId', null)
      setValue('simpleCompanyId', null)
      toggleShares()
    }
    setModalType(null)
  }

  // Set modal content
  let title = ''
  let modalContent = ''
  if (modalType === 'agreements') {
    title = 'Purchase Agreements'

    modalContent = (
      <div className='modal-padding'>
        {documents?.length > 0 &&
          documents?.map((d) => (
            <Button
              key={d.name}
              ariaLabel={`View ${d.name}`}
              className='min-w-full b_16_semibold gray5 agreement-box'
              onClick={() => {
                handleViewDocument(d.name)
                window.open(`${d.url}${selectedAccount?.entityId? `&entityId=${selectedAccount.entityId}`: ''}`, '_blank', 'noopener,noreferrer')
              }}
            >
              <span className='b_16_semibold gray5'>{d.name}</span>
              <img src={images['right-arrow']}  alt={`More ${d.name}`}/>
            </Button>
          ))}
        <div className='modal-center-btn'>
          <Button onClick={() => setModalType(null)} ariaLabel='Close Modal'>OK</Button>
        </div>
      </div>
    )
  }
  if (modalType === 'entity') {
    title = 'Select Entity'
    modalContent = (
      <div className='modal-padding'>
        <div className='entity-row'>
          <label className='b_16_regular gray4 entity-select-label'>
            Available Entity
          </label>
          <select
            className='entity-select'
            aria-label='purchase-as'
            onChange={(e) => setEntity(e)}
            disabled={commitLoading}
            data-testid='purchase-as'
          >
            {accounts?.length > 0 &&
              accounts.map((e, i) => (
                <option value={i} key={i}>
                  {e.accountName}
                </option>
              ))}
          </select>
          <Button onClick={updateEntity}>Confirm Entity</Button>
        </div>
      </div>
    )
  }

  if (modalType === 'payment') {
    title = 'Select Payment Method(s)'
    modalContent = (
      <div className='modal-padding'>
        <ConditionalRender isVisible={PurchaseWithSharesAllowed}>
          <div>
            <div className='modal-row'>
              <span className='modal-icon-row'>
                <img
                  src={images.landscape}
                  alt='Shares icon'
                  className='purchase-icon'
                />
                <div style={{}}>
                  <span className='no-padding'>
                    <p className='b_18_regular gray5'>Shares</p>
                  </span>
                </div>
              </span>
              <Toggle
                handleChange={toggleShares}
                checked={purchaseWithShares}
                ariaLabel='payWithSharesToggle'
              />
            </div>
            <div>
              {purchaseWithShares && (
                <Controller
                  control={control}
                  name='sellCompanyIdControl'
                  render={({ field }) => (
                    <CustomSelect
                      name='simpleCompanyId'
                      ariaLabel='sellCompanyId-input'
                      field={field}
                      label='Shares available to sell'
                      placeholder='No Company Holdings Selected'
                      extraLabel=' '
                      selectedOptions={selectedOptions}
                      optionsList={sellCompanies}
                      onChange={(option) => updateSharesDropdown(option)}
                      className='shares-select'
                      content="Only shares you've held for more than 90 days and are currently eligible to be sold, are available for trading."
                    />
                  )}
                />
              )}
              <ConditionalRender isVisible={selectedOptions?.length === 1}>
                <div className='modal-share-calculation'>
                  <div className='modal-buy-order-row'>
                    <span className='b_16_regular gray4'>
                      Estimated Shares To Be Sold:{' '}
                    </span>
                    <span className='b_16_regular gray4'>
                      {renderTotalsOrDash(
                        order?.sellShares,
                        formatWholeNumber(order?.sellShares)
                      )}
                    </span>
                  </div>
                  <div className='modal-buy-order-row'>
                    <span className='b_16_regular gray4'>
                      Remaining Shares:{' '}
                    </span>
                    <span className='b_16_regular gray4'>
                      {renderTotalsOrDash(
                        order?.remainingUnsoldShares,
                        formatWholeNumber(order?.remainingUnsoldShares)
                      )}
                    </span>
                  </div>
                  <div className='modal-buy-order-row'>
                    <span className='b_16_regular gray4'>
                      Estimated Share Price:{' '}
                    </span>
                    <span className='b_16_regular gray4'>
                      {renderTotalsOrDash(
                        order?.sellSharePrice,
                        formatDecimal(order?.sellSharePrice)
                      )}
                    </span>
                  </div>
                  {order?.sellTransactionFee > 0 && (
                    <div className='modal-buy-order-row'>
                      <div className='b_16_regular gray4'>
                        Transaction Fee:
                        <Tooltip
                          content={`This ${order.sellTransactionFeeRate}% fee supports the maintenance of Linqto’s private market place.`}
                          showHover={showTransactionFeeTooltip}
                          setShowHover={setShowTransactionFeeTooltip}
                        />
                      </div>
                      <div className='b_16_regular gray4'>
                        {renderTotalsOrDash(
                          order?.sellTransactionFee,
                          formatDecimal(order?.sellTransactionFee * -1)
                        )}
                      </div>
                    </div>
                  )}
                  <div className='modal-buy-order-row'>
                    <span className='b_16_regular gray4'>
                      Cost Basis of Shares:{' '}
                    </span>
                    <span className='b_16_regular gray4'>
                      {renderTotalsOrDash(
                        order?.totalOriginalHeldSharePrice,
                        formatDecimal(order?.totalOriginalHeldSharePrice)
                      )}
                    </span>
                  </div>
                  <div
                    className='modal-buy-order-row'
                    style={{ marginBottom: '16px' }}
                  >
                    <span className='b_16_regular gray4'>
                      Your Projected Gain:
                      <Tooltip
                        content='These gains/losses are estimated. Consult a tax advisor for potential tax implications.'
                        showHover={showHover}
                        setShowHover={setShowHover}
                      />{' '}
                    </span>
                    <span className='b_16_regular gray4'>
                      {renderTotalsOrDash(
                        order?.projectedGain,
                        formatDecimal(order?.projectedGain) || '-'
                      )}
                    </span>
                  </div>
                </div>
              </ConditionalRender>
              {showSharesModalError && selectedAccount?.length !== 1 && (
                <div style={{ marginBottom: '16px' }}>
                  <span className='red'>
                    Please select company or toggle off.
                  </span>
                </div>
              )}
            </div>
          </div>
          <hr className='modal-divider' />
        </ConditionalRender>
        <div className='modal-row'>
          <span className='modal-icon-row'>
            <img
              src={images.payment}
              alt='Cash icon'
              className='purchase-icon'
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='no-padding'>
                <p className='b_18_regular gray5'>Cash Balance</p>
                {formatDecimal(selectedAccount?.amountAvailable)}
              </span>
              {PurchaseWithSharesAllowed && (
                <span className='b_16_regular no-padding'>
                  Covers balance after selling shares and any discounts applied.{' '}
                </span>
              )}
              {total > cashBalance && (
                <Button
                  className='b_16_semibold simple-purchase-link'
                  onClick={() => goToAddFunds()}
                  ariaLabel='Add Funds to your account'
                >
                  + Add Funds
                </Button>
              )}
            </div>
          </span>
          <Toggle
            handleChange={toggleCash}
            checked={purchaseWithCash}
            ariaLabel='puchaseCashToggleSwitch'
          />
        </div>
        <div className='modal-center-btn'>
          <Button onClick={updatePaymentMethod}>Apply Payment(s)</Button>
        </div>
      </div>
    )
  }

  if (modalType === 'discounts') {
    title = 'Apply Discount'
    modalContent = (
      <div className='modal-padding'>
        <ConditionalRender
          isVisible={PurchaseCreditEnabled && totalPurchaseCredits > 0}
        >
          <div className='modal-row'>
            <span className='modal-icon-row'>
              <img
                src={images.gift}
                alt='Shares icon'
                className='purchase-icon'
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '500px',
                  width: '100%'
                }}
              >
                <span className='no-padding'>
                  <p className='b_18_regular gray5'>
                    Purchase Credits{' '}
                    <Tooltip
                      content='Purchase Credits are valid for 30 days and can be combined with Linqto Bucks and other offers.'
                      showHover={showPurchaseCreditTooltip}
                      setShowHover={setShowPurchaseCreditTooltip}
                    />
                  </p>
                </span>
                <span className='no-padding'>
                  {formatDecimal(totalPurchaseCredits)}
                </span>
                <span className='b_16_regular no-padding gray3 modal-bottom'>
                  Cannot be combined when paying with shares
                </span>
              </div>
            </span>
            <Toggle
              handleChange={togglePurchaseCredits}
              checked={purchaseCreditsEnabled}
              ariaLabel='puchase credits toggle switch'
            />
          </div>
          <hr className='modal-divider' />
        </ConditionalRender>
        <div className='modal-row modal-bottom'>
          <span className='modal-icon-row'>
            <img
              src={images['linqto-logo']}
              alt='linqto icon'
              className='purchase-icon'
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='no-padding'>
                <p className='b_18_regular gray5'>Linqto Bucks</p>
                {formatDecimal(linqtoBucks)}
              </span>
              <span className='b_16_regular gray3'>
                Cannot be combined with other promotions
              </span>
              {linqtoBucksEnabled && (
                <div className='linqto-bucks-terms b_16_regular'>
                  {linqtoBucks < maxOrderLinqtoBucksPercent * amount &&
                    'Linqto Bucks cannot be combined with other promotions. '}
                  {linqtoBucks >= maxOrderLinqtoBucksPercent * amount &&
                    `Linqto Bucks can be used for up to ${
                      maxOrderLinqtoBucksPercent * 100
                    }% of the order and cannot be combined with other promotions. `}
                  By using Linqto Bucks, I have read and agree to be bound by
                  the{' '}
                  <a
                    className='inline-text-link b_16_regular'
                    href={getDomain('/reward-terms-of-use', true)}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Linqto Bucks Terms and Conditions.
                  </a>
                </div>
              )}
            </div>
          </span>
          <Toggle
            handleChange={toggleLinqtoBucks}
            checked={linqtoBucksEnabled}
            disabled={isPromoCodeValid}
            ariaLabel='linqtoBucksToggle'
          />
        </div>
        <hr className='modal-divider' />
        <div className='modal-row'>
          <span className='modal-icon-row'>
            <div className='linqto-bucks-row'>
              {(() => {
                if (!isPromoCodeValid) {
                  return (
                    <>
                      <div
                        className={`input-group promo-input-wrapper ${
                          linqtoBucksEnabled || confirmOrderLoading
                            ? 'disabled'
                            : ''
                        } ${promoCodeError ? 'error' : ''}`}
                      >
                        <input
                          disabled={linqtoBucksEnabled || confirmOrderLoading}
                          type='text'
                          aria-label='promoCodeInput'
                          className='promo-input discount-input'
                          value={promoCode}
                          placeholder='Enter Promo Code'
                          onChange={onPromoCodeChange}
                          data-testid='promoCodeInput'
                        />
                        {promoCodeError && (
                          <ErrorMessage message='Promo code not found' />
                        )}
                      </div>
                    </>
                  )
                } else {
                  return (
                    <div className='input-group promo-input-wrapper promo-input-success discount-input'>
                      <input
                        type='text'
                        aria-label=''
                        className={`promo-input discount-input ${
                          confirmOrderLoading ? 'promo-input-disabled' : ''
                        }`}
                      />
                      <span className='promo-name-wrapper b_16_semibold discount-name-wrapper'>
                        {promoCode}{' '}
                        <img
                          onClick={removePromoCode}
                          src={images['promo-close']}
                          alt='Remove promo code'
                          role='button'
                          aria-label='Remove promo code'
                          tabIndex={0}
                        />
                      </span>
                    </div>
                  )
                }
              })()}
            </div>
          </span>
        </div>
        <div className='modal-center-btn'>
          <Button onClick={updateDiscounts}>Apply Discount(s)</Button>
        </div>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className='simple-purchase-container'>
        <div className='buy-order-header simple-purchase-header'>
          <div className='buy-order-company-name'>
            <img
              className='buy-order-company-icon simple-purchase-icon'
              src={company?.iconUrl}
              alt={company?.name}
            />
            <div className='company-name heading_5'>{company?.name}</div>
          </div>
        </div>
        <div className='buy-order-row simple-purchase'>
          <span className='b_16_regular gray5 simple-purchase'>
            Price Per Share
          </span>
          <span className='b_16_regular gray5 simple-purchase'>
            {formatDecimal(sharePrice)}
          </span>
        </div>
        <div className='buy-order-row'>
          <span className='b_16_regular gray5 simple-purchase'>
            Estimated Shares
            <Tooltip
              content='You are purchasing the number of units in the series that represents the equivalent number of shares identified.'
              showHover={showHover}
              setShowHover={setShowHover}
            />
          </span>
          <span className='b_16_regular gray5 simple-purchase'>
            {formatWholeNumber(shares)}
          </span>
        </div>
        <div className='buy-order-row'>
          <span className='b_16_regular gray5 simple-purchase'>
            Investment Amount
          </span>
          <span className='b_16_semibold simple-purchase'>
            {formatDecimal(amount)}
          </span>
        </div>
        <hr className='divider' />
        <div className='buy-order-row'>
          <span className='b_16_regular gray3'>Apply Discounts</span>
          <Button
            className='b_16_semibold simple-purchase-link'
            onClick={() => setModalType('discounts')}
          >
            Add Discounts
          </Button>
        </div>
        {PurchaseCreditEnabled && creditsUsed > 0 && (
          <div className='buy-order-row'>
            <span className='b_16_regular gray5'>Purchase Credits</span>
            <span className='b_16_semibold gray5'>
              -{formatDecimal(creditsUsed)}
            </span>
          </div>
        )}
        {linqtoBucksUsed > 0 && (
          <div className='buy-order-row'>
            <span className='b_16_regular gray5'>Linqto Bucks</span>
            <span className='b_16_semibold gray5'>
              {' '}
              -{formatDecimal(linqtoBucksUsed)}
            </span>
          </div>
        )}
        {discountAmount > 0 && (
          <div className='buy-order-row'>
            <span className='b_16_regular gray5'>
              Promo Discount:{' '}
              <span className='promo b_16_regular gray3'>{promoCode}</span>
            </span>
            {discountPercent > 0 && (
              <div className='promo b_16_semibold gray5'>
                -{formatDecimal(discountPercent * 100, false, 0)}% (
                {formatDecimal(discountAmount)})
              </div>
            )}
            {!discountPercent && discountAmount > 0 && (
              <div className='promo b_16_semibold gray5'>
                -{formatDecimal(discountAmount)}
              </div>
            )}
          </div>
        )}
        {accounts?.length > 1 && (
          <>
            <hr className='divider' />
            <div className='buy-order-row'>
              <span className='b_16_regular gray3'>Pay As</span>
              <Button
                className='b_16_semibold simple-purchase-link'
                onClick={() => setModalType('entity')}
              >
                Change Entity
              </Button>
            </div>
            <div className='buy-order-row'>
              <span className='b_16_regular gray5'>Account Selected</span>
              <span className='b_16_semibold gray5'>
                {selectedAccount?.accountName}
              </span>
            </div>
          </>
        )}
        <hr className='divider' />
        <div className='buy-order-row'>
          <span className='b_16_regular gray3'>Pay With</span>
          <Button
            className='b_16_semibold simple-purchase-link'
            onClick={() => setModalType('payment')}
          >
            Add Payment Method
          </Button>
        </div>
        {purchaseWithShares && accountLabel && (
          <div className='buy-order-row'>
            <span className='b_16_regular gray5'>
              Shares: <span className='b_16_regular gray3'>{accountLabel}</span>
            </span>
            <span className='b_16_semibold gray5'>
              -{formatDecimal(sellAmount)}
            </span>
          </div>
        )}
        <div className='buy-order-row'>
          <span className='b_16_regular gray5 simple-purchase'>Cash</span>
          <span className='b_16_semibold gray5 simple-purchase'>
            {formatDecimal(total > 0 ? -total : 0)}
          </span>
        </div>
        <hr className='final-divider' />
        <form onSubmit={handleSubmit(handleCommitOrder)}>
          {total < 0 && (
            <div className='buy-order-row cash-credit-box'>
              <span className='b_16_regular gray5'>Credit to Cash Account</span>
              <span className='b_16_regular gray5'>
                {formatDecimal(total * -1)}
              </span>
            </div>
          )}
          <div className='buy-order-row'>
            <span className='purchase-agreement-check'>
              <Checkbox
                name='confirmOrderCheckbox'
                ariaLabel='confirm order checkbox'
                disabled={commitLoading}
                checkboxClass='confirm-checkbox'
              >
                I have read and agree to the
                <a
                  onClick={() => setModalType('agreements')}
                  className='b_16_semibold simple-purchase-link'
                  role='button'
                  aria-label='view purchase agreements'
                >
                  purchase agreements.
                </a>
              </Checkbox>
            </span>
          </div>
          {confirmOrderCheckbox && isFormValid && (
            <div className='buy-order-row'>
              <span className='b_16_regular gray4'>
                Final price may vary up to 5% during periods of high volume.
              </span>
            </div>
          )}
          {total > cashBalance && (
            <div className='buy-order-row sell-offer-row'>
              <span className='red'>
                Please{' '}
                <Button className='inline-text-link' onClick={goToAddFunds}>
                  add funds
                </Button>{' '}
                to complete this order
              </span>
            </div>
          )}
          {errors?.sharesInsufficent && (
            <div className='buy-order-row total-spaced'>
              <span className='red'>{errors?.sharesInsufficent?.message}</span>
            </div>
          )}
          <div className='buy-order-row flex-end' style={{ marginTop: '36px' }}>
            <Button
              type='submit'
              loading={commitLoading}
              disabled={disabled}
              onClick={trackPlaceOrder}
              customClass='place-order-button'
            >
              Place Order
            </Button>
          </div>
        </form>

        {modalType && (
          <Modal
            crossToClose
            modalHeader={title}
            hideModal={() => handleCloseModal()}
            innerStyle='text-left'
          >
            {modalContent}
          </Modal>
        )}
      </div>
    </div>
  )
}

export default SimplifiedPurchase
