import React, { useEffect, useState } from 'react'
import { PageLoading, TopBanner } from 'components'
import { seoTitleTemplate } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getPortfolio } from 'slices/portfolioSlice'
import InvestmentTabContainer from './partials/InvestmentTabContainer'
import TotalInvestment from './partials/TotalInvestment'
import InvestmentNoData from './partials/InvestmentNoData'
import CurrentInvestmentsTab from './partials/CurrentInvestments/CurrentInvestmentsTab'
import HistoryTab from './partials/History/HistoryTab'
import EntityDropDown from './partials/EntityDropDown'
import DownloadSummary from './partials/DownloadSummary'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import RecurringInvestmentCTA from './partials/RecurringInvestmentCTA'

const PortfolioComponent = () => {
  const [tab, setTab] = useState('current')
  const [groupBy, setGroupBy] = useState('account')
  const dispatch = useDispatch()
  const { portfolioContentCards } = useSelector(state => state.commonSlice)
  const { isLoggedIn, entities, recentOrders, cashPaymentEnabled, total, totalInvestmentValue } = useSelector(state => state.portfolioSlice)
  const hasEntities = entities?.length > 0

  const [pageLoading, setPageLoading] = useState(true)
  const [showTopBanner, setShowTopBanner] = useState(null)

  useEffect(() => {
    (async () => {
      const res = await dispatch(getPortfolio())
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        if (localStorage?.getItem('cancel-recurring-investment')) {
          setShowTopBanner({ title: 'Recurring Investment Cancelled', body: 'Your recurring investment is successfully cancelled for the future.' })
          localStorage?.removeItem('cancel-recurring-investment')
        }
      }
      setPageLoading(false)
    })()
  }, [])

  const handleChange = type => setTab(type)
  const totalValue = cashPaymentEnabled ? total : totalInvestmentValue

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('My Portfolio')} />
        <PageLoading />
      </>
    )
  }

  let currentInvestmentComponent = null
  if (tab === 'current') {
    if (totalValue === 0) {
      currentInvestmentComponent = <InvestmentNoData tab={tab} />
    } else {
      currentInvestmentComponent = <CurrentInvestmentsTab groupBy={groupBy} />
    }
  } else {
    currentInvestmentComponent = <HistoryTab tab={tab} groupBy={groupBy}/>
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('My Portfolio')} />
      <div className='portfolio-container'>
        <div className='page-container'>
          <div className='inner-container'>
            {showTopBanner && (
              <TopBanner
                title={showTopBanner.title}
                body={showTopBanner.body}
                hideBanner={() => setShowTopBanner(null)}
                type={showTopBanner.type}
              />
            )}
            {isLoggedIn && portfolioContentCards?.length ? (
              <div className={`messaging-carousel-container -mt-5 md:-mt-9 ${portfolioContentCards?.length === 1 ? 'mb-4 md:mb-6' : ''}`}>
                <MessagingCarousel data={portfolioContentCards} page='Portfolio' />
              </div>) : null}
            <div className='page-header'>
              <h1>My Portfolio</h1>
              <div className='page-actions'>
                {hasEntities && <EntityDropDown groupBy={groupBy} setGroupBy={setGroupBy}/>}
                {recentOrders?.length > 0 && <DownloadSummary />}
              </div>
            </div>
            <TotalInvestment />
            <RecurringInvestmentCTA />
            <InvestmentTabContainer onChange={handleChange} tab={tab} />
            <div className='investments-container'>
              {currentInvestmentComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioComponent
