import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageLoading } from 'components'
import ReferralInfo from './partials/ReferralInfo'
import ReferralError from './partials/ReferralError'
import SignUpForm from './partials/SignUpForm'
import AccreditationModal from './partials/AccreditationModal'
import { seoTitleTemplate, Mixpanel, getDomain, MOBILE_WIDTH_SIZE, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { getSignupReferral, signUp, clearErrorState } from 'slices/userSlice'
import { handleReCaptchaVerify } from 'utils/googleRecaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import SignUpHeader from './partials/SignUpHeader'
import SignUpTest from './SignUpTest'
import SignUpReferFriend from './SignUpReferFriend'
import useWindowSize from 'hooks/useWindowSize'
import { useLDFlags } from 'utils/LaunchDarkly'

const SignUp = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = location.search
  const r = qs.parse(query, { ignoreQueryPrefix: true })?.r || null
  const ria = qs.parse(query, { ignoreQueryPrefix: true })?.ria || null
  const irclickid = qs.parse(query, { ignoreQueryPrefix: true })?.irclickid || null
  const [referralError, setReferralError] = useState(false)
  const [referralErrorMessage, setReferralErrorMessage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [referralType, setReferralType] = useState(r ? 'linqto-referral' : ria ? 'advisor-referral' : '')
  const [submitLoading, setSubmitLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const { signUpPageTest } = useLDFlags(['signUpPageTest'])
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  const pathname = location.pathname
  const [oAuthToken, setOAuthToken] = useState((pathname === '/signup/create-account' ? JSON.parse(sessionStorage?.getItem('oAuthToken')) : null) || null)

  const fromOrigination = qs.parse(query, { ignoreQueryPrefix: true })?.fromOrigination || false
  const fromOriginationCompany = qs.parse(query, { ignoreQueryPrefix: true })?.company || null
  const referralCode = r || ria || null
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { executeRecaptcha } = useGoogleReCaptcha()

  const {
    isSignedIn,
    isUserReturned,
    referrerName,
    riaFirmName,
    signupBonus,
    referrerLogoUrl,
    hasReferrer,
    accreditationTerms,
    err
  } = useSelector((state) => state.userSlice)

  useEffect(() => {
    if (oAuthToken) {
      Mixpanel.track('View OAuth Page')
      Fullstory.track('View OAuth Page')
    }
  }, [oAuthToken])

  const getSignupPage = () => {
    dispatch(getSignupReferral({ referralCode, referralType })).then(
      ({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.registerUTM()
          const ReferralByAnotherUser = payload?.hasReferrer && !payload?.isRiaReferral
          const ReferredByAnAdvisor = payload?.hasReferrer && payload?.isRiaReferral
          const ReferralCredit = payload?.signupBonus || 0
          Mixpanel.register({ 'Referral by another user': ReferralByAnotherUser, 'Referred by an advisor': ReferredByAnAdvisor, 'Referral credit': ReferralCredit, Platform: 'Web' })
          Mixpanel.track('View Sign Up Page')
          Fullstory.track('View Sign Up Page')
          if (ReferralByAnotherUser) {
            setReferralType('linqto-referral')
          } else if (ReferredByAnAdvisor) {
            setReferralType('advisor-referral')
          }
          if (isSignedIn) {
            if (payload.hasReferrer) {
              history.push(`/registered-investment-advisor?ria=${ria}`)
            } else {
              history.push('/products')
            }
          }
          setPageLoading(false)
          if (!payload?.hasReferrer) {
            if (payload?.notifyMessage) {
              setReferralError(true)
              setReferralErrorMessage({
                message: payload?.notifyMessage,
                title: 'Referral Link Error',
                actionLabel: 'OK',
                action: 'redirectToSignIn'
              })
            } else if (referralCode) {
              setReferralError(true)
              setReferralErrorMessage({
                message:
                  "The referral link has expired or the referrer doesn't exist.",
                title: 'Something Went Wrong',
                actionLabel: 'Continue to sign up',
                action: 'close'
              })
            }
          }
        }
      }
    )
  }
  useEffect(() => {
    if (ria) {
      localStorage?.setItem(
        'ria-route',
        `/registered-investment-advisor?ria=${ria}`
      )
    }
  }, [ria])

  useEffect(() => {
    if (isUserReturned) {
      if (!isSignedIn || (isSignedIn && ria)) {
        getSignupPage()
      } else {
        history.push('/products')
      }
    }
  }, [isUserReturned, isSignedIn])

  useEffect(() => {
    if (showModal) {
      Mixpanel.track('View Accreditation Terms')
    }
  }, [showModal])

  // cleanup to reset err when component unmounts (link from signup to signin page)
  useEffect(() => () => dispatch(clearErrorState()), [])

  useEffect(() => {
    // solving if user go back on the browser after going to the create account page
    window.onpopstate = e => {
      if (pathname === '/signup/create-account') {
        sessionStorage.removeItem('oAuthToken')
        setOAuthToken(null)
      }
    }
  })

  useEffect(() => {
    // solving if user landed to create-account page without being clicking on aouth buttons
    // user will be directed to sign up
    if (pathname === '/signup/create-account' && !oAuthToken) {
      history.push('/signup')
    }
    if (pathname === '/signup') {
      setOAuthToken(null)
    }
  }, [pathname])

  const onSubmit = async (formValues) => {
    // dispatch action to clean up error & form state (from uAuth flow), then redirect user to signup page
    if (err && err === 500 && oAuthToken) {
      dispatch(clearErrorState())
      history.goBack()
      methods.reset()
      return
    }
    setSubmitLoading(true)
    const redirectUrl = fromOrigination
      ? fromOriginationCompany
        ? `/sell/${fromOriginationCompany}`
        : '/sell/'
      : ria
        ? `/registered-investment-advisor?ria=${ria}`
        : ''
    const token = await handleReCaptchaVerify(executeRecaptcha, 'signUp')
    const mixpanelDistinctId = Mixpanel.getDistinctId()
    let bodyTosend = {
      accreditedCountry: '',
      selfAccreditation: 'UNKNOWN',
      fromOrigination: fromOrigination,
      redirectUrl,
      token,
      externalTrackingId: mixpanelDistinctId,
      irclickid
    }
    if (oAuthToken) {
      Mixpanel.track('Click Sign Up on OAuth Page')
      Fullstory.track('Click Sign Up on OAuth Page')
      bodyTosend.oAuthType = oAuthToken.type
      bodyTosend.marketingOptIn = formValues?.marketingOptIn

      if (oAuthToken.type === 'Google') {
        bodyTosend.googleAccessToken = oAuthToken.value
      }

      if (oAuthToken.type === 'Apple') {
        bodyTosend.appleAccessToken = oAuthToken.value
        bodyTosend.firstName = oAuthToken.firstName
        bodyTosend.lastName = oAuthToken.lastName
      }

      if (fromOrigination) {
        bodyTosend.accreditOption = formValues.accreditOption
      }
    } else {
      bodyTosend = {
        ...formValues,
        ...bodyTosend
      }
    }
    dispatch(signUp(bodyTosend)).then(({ meta, ...data }) => {
      setSubmitLoading(false)
      if (meta.requestStatus === 'fulfilled') {
        if (oAuthToken) {
          Mixpanel.track('Confirm Registration-Success')
          Fullstory.track('Confirm Registration-Success')
          if (redirectUrl) {
            history.push(redirectUrl)
          } else {
            history.push('/profile')
          }
        } else {
          history.push({
            pathname: '/activate',
            state: { email: bodyTosend.email }
          })
        }
      } else {
        const isServerError = data?.payload?.data?.error || ''
        if (isServerError === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED') {
          methods.setError('email')
        }
      }
    })
  }

  const closeModal = () => {
    setReferralError(false)
    if (referralErrorMessage?.action === 'redirect') {
      const message = referralErrorMessage.message
      if (message.indexOf('Forge Trust') >= 0) {
        history.push('/rewards')
      } else {
        window.location.href = getDomain('/', true)
      }
    } else if (referralErrorMessage?.action === 'redirectToSignIn') {
      history.push('/products')
    }
  }

  const onOAuthSuccess = async (accessToken, type, firstName = '', lastName = '') => {
    dispatch(clearErrorState())
    const token = { type, value: accessToken, firstName, lastName }
    setOAuthToken(token)
    sessionStorage.setItem('oAuthToken', JSON.stringify({ ...token }))
    let url = ''
    if (r) url = `?r=${r}`
    if (ria) url = `?ria=${ria}`
    if (fromOrigination) url = `?fromOrigination=${fromOrigination}`
    if (fromOriginationCompany) url += `&company=${fromOriginationCompany}`
    // when irclickId is present, append it to the url
    if (irclickid) {
      if (url.match(/^\?[a-z]+=/gm)) {
        url += `&irclickid=${irclickid}`
      } else {
        url = `?irclickid=${irclickid}`
      }
    }
    history.push(`/signup/create-account${url}`)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sign Up')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Sign Up')}>
        <link rel='canonical' href='https://app.linqto.com/signup' />
      </SeoMeta>
      {referralError && (
        <ReferralError
          referralErrorMessage={referralErrorMessage}
          closeModal={closeModal}
        />
      )}
      {showModal && (
        <AccreditationModal
          hideModal={() => setShowModal(false)}
          accreditationTerms={accreditationTerms}
        />
      )}
      { r
        ? <SignUpReferFriend
          oAuthToken={oAuthToken}
          onOAuthSuccess={onOAuthSuccess}
          onSubmit={onSubmit}
          fromOrigination={fromOrigination}
          setShowModal={setShowModal}
          submitLoading={submitLoading}
        />
        : <>
          {!isMobile && <SignUpTest
            oAuthToken={oAuthToken}
            onOAuthSuccess={onOAuthSuccess}
            onSubmit={onSubmit}
            fromOrigination={fromOrigination}
            setShowModal={setShowModal}
            submitLoading={submitLoading}
          />
          }
          {(signUpPageTest === 'control' || isMobile) &&
        <div className='page-container'>
          <div className='inner-container wide-inner-container'>
            <div className='signup-container'>
              <div className='grid'>
                <div className='column eight sixteen-mobile'>
                  <div className='signup-header-container'>
                    <SignUpHeader
                      pathname = { pathname}
                    />
                    {hasReferrer && (
                      <ReferralInfo
                        referrerName={referrerName}
                        referralAmount={signupBonus}
                        referrerLogo={referrerLogoUrl || ''}
                        riaFirmName={riaFirmName}
                        type={referralType}
                      />
                    )}
                  </div>
                </div>
                <div className='column eight sixteen-mobile'>
                  <div className='signup-form'>
                    <SignInOAuthBtns
                      oAuthToken={oAuthToken}
                      onOAuthSuccess={onOAuthSuccess}
                    />
                    <FormProvider {...methods}>
                      <SignUpForm
                        onSubmit={onSubmit}
                        isFromOrigination={fromOrigination}
                        setShowModal={setShowModal}
                        submitLoading={submitLoading}
                        oAuthToken={oAuthToken}
                      />
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          }
        </>
      }
    </>
  )
}

export default SignUp
