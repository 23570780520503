import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConditionalRender, PageLoading, Wrapper, Button } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import { seoTitleTemplate, MOBILE_WIDTH_SIZE, Mixpanel, Fullstory } from 'utils'
import InvestSection from './partials/InvestSection'
import { getBundles, getInvestPage } from 'slices/investSlice'
import useFirstRender from 'hooks/useFirstRender'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import InvestAccountBanner from './partials/InvestAccountBanner'
import { images } from 'assets'
import SearchCompaniesInput from './partials/SearchCompaniesInput'
import useWindowSize from 'hooks/useWindowSize'
import useScrollEndHeight from 'hooks/useScrollEndHeight'

const InvestRetailBanner = ({ updateAccreditation }) => (
  <div className='bg-accessibleIndigo rounded-2xl px-10 py-12 relative mb-12 h-[417px] md:h-[320px] md:rounded-tr-[80px]'>
    <img src={images['retail-banner-bg-desktop']} alt='Update Accreditation' className='absolute bottom-0 right-0 h-[320px] hidden md:rounded-tr-[80px] md:block'/>
    <img src={images['retail-banner-bg-mobile']} alt='Update Accreditation' className='absolute bottom-0 right-0 w-[204px] rounded-br-2xl md:hidden'/>
    <div className='mb-5 text-2xl text-white font-CodecColdBold md:text-4xl'>Get Access to All Our Companies</div>
    <div className='mb-8 text-white font-CodecColdRegular md:max-w-[80%] md:text-lg'>These investment options are only available to accredited investors. Please update your accreditation status to place an order.</div>
    <Button onClick={updateAccreditation}>Update Accreditation <img src={images['btn-arrow']}  alt='Update Accreditation' className='ml-2'/></Button>
  </div>
)

const InvestRevamp = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const firstRender = useFirstRender()
  const { width } = useWindowSize()
  const heightRef = useScrollEndHeight()
  
  const { isRetailPurchaser, companies, topSellers, accreditedStatus, bundle, retailCompanies, nonRetailCompanies, verticals } = useSelector(state => state.investSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { investContentCards, isMixpanelExist } = useSelector(state => state.commonSlice)
  
  const { BundlePurchaseEnabled, RetailPurchaseAllowed } = featureFlags || {}

  const [pageLoading, setPageLoading] = useState(true)
  const [showBanner, setShowBanner] = useState(true)

  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'
  const isMobile = width <= MOBILE_WIDTH_SIZE

  useEffect(() => {
    (async () => {
      const res = await dispatch(getInvestPage('', '', '', firstRender))
      const { meta, payload } = res
      if (meta.requestStatus === 'fulfilled') {
        await dispatch(getBundles())
        // after fetching data, go down to the users last scroll position
        const investFeedScroll = localStorage?.getItem('investFeedScroll')
        if (investFeedScroll) {
          window.scrollTo({ top: parseInt(investFeedScroll) })
          localStorage?.removeItem('investFeedScroll')
        } else {
          window.scrollTo({ top: 0 })
        }
        setPageLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    return () => {
      if (heightRef?.current > 0) {
        Mixpanel.track('Scroll on Product Invest Page', { 'Amount scrolled': `${heightRef?.current}%` })
      }
    }
  }, [])

  useEffect(() => {
    if (isMixpanelExist) {
      Mixpanel.setPeople({ 'Is Accredited': accreditedStatus === 'ACCREDITED' })
    }
    Fullstory.setUserProperty({ is_accredited: accreditedStatus === 'ACCREDITED' })
  }, [isMixpanelExist, accreditedStatus])

  const viewAllCompanies = () => { 
    if(RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED') {
      history.push('/products-list?list=retail')
    } else {
      history.push('/products-list')
    }
  }

  const showProductDetails = (item, section = '') => {   
    localStorage?.setItem('investFeedScroll', window.scrollY)
    history.push(`/products/${item?.urlName}`)
    Mixpanel.track('Click Company on Product Invest Page', { 'Company Name': item?.companyName, 'Share Price': item?.sharePrice, 'Section': section })
    Fullstory.track('Card Click', { name: 'Company', company: item?.companyName, share_price: item?.sharePrice })
  }

  const showBundleDetails = (item) => {
    localStorage?.setItem('investFeedScroll', window.scrollY)
    localStorage?.setItem('investFeedScroll', window.scrollY)
    Mixpanel.track('Click Bundle on Product Invest Page', { Companies: companies, 'Bundle ID': bundleId })
    history.push(`/bundle/${bundleId}`)
    history.push(`/bundle/${item?.bundleId}`)
  }

  const updateAccreditation = () => { 
    history.push('/investor-status')
  }

  const onItemClick = (vertical) => {
    if(vertical === 'all') {
      history.push('/products-list')
    } else {
      history.push(`/products-list?vertical=${encodeURIComponent(vertical)}`)
    }
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }
  
  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <Wrapper className='page-container invest-container'>
        <div className='inner-container'>
          <div className='container'>
            <InvestAccountBanner setShowBanner={setShowBanner} />
            <div className='relative'>
            <div className='flex gap-2 mb-4 overflow-x-auto md:mb-8' role='group' aria-label='Filter sectors'>
              {verticals?.length > 0 && verticals?.slice(0, 4).map((vertical, i) =>
                <Button
                  className='px-3 rounded-[100px] bg-gray1 flex-[0_0_auto] whitespace-nowrap font-CodecColdRegular h-10'
                  key={`vertical${i}`}
                  data-testid={`vertical-${i}`}
                  ariaLabel={`Filter by ${vertical}`} onClick={() => onItemClick(vertical)}>{vertical}</Button>
              )}
              <select
                onChange={(e) => {
                  onItemClick(e.target.value)
                }}
                data-testid='sector-select'
                className='appearance-none rounded-[100px] bg-gray1 font-CodecColdRegular border-0 hover:border-0 w-[110px] pr-0 h-10'
                aria-label='Filter by sectors'>
                <option value=''>Sectors</option>
                <option value='all'>All Sectors</option>
                { verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
              </select>
             {!isMobile && <SearchCompaniesInput />}
            </div>
            {isMobile && <SearchCompaniesInput />}  
            {investContentCards?.length ? (
              <div className={`messaging-carousel-container ${!showBanner ? '-mt-5 md:-mt-9' : 'mt-4 mb-14'}`}>
                <MessagingCarousel data={investContentCards} page='Invest' />
              </div>) : null}
              <ConditionalRender isVisible={RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED'}>
              <InvestSection title='Invest Now' list={retailCompanies} listType='products' listStyle='thumbnail' onItemClick={showProductDetails} />
              <InvestRetailBanner updateAccreditation={updateAccreditation} />
              <InvestSection title='' list={nonRetailCompanies?.slice(0, isMobile ? 10 : 20)} listType='products' listStyle='list'  onItemClick={showProductDetails}/>
              <div className='flex justify-center w-full'>
                <Button onClick={viewAllCompanies} mode='secondary'>View All</Button>
              </div>
            </ConditionalRender>
            <ConditionalRender isVisible={!RetailPurchaseAllowed || !isRetailPurchaser || accreditedStatus === 'ACCREDITED'}>
              <InvestSection title='Top Sellers' list={topSellers} listType='products' listStyle='thumbnail' onItemClick={showProductDetails} hideTag='TOP_SELLER'/>
              {BundlePurchaseEnabled && <InvestSection title='Bundles' list={bundle?.bundles} listType='bundles' listStyle='thumbnail' onItemClick={showBundleDetails} />}
              <InvestSection title='Companies' list={companies?.slice(0, isMobile ? 10 : 20) } listType='products' listStyle='list'  onItemClick={showProductDetails}/>
              <div className='flex justify-center w-full'>
                <Button onClick={viewAllCompanies} mode='secondary'>View All</Button>
              </div>
            </ConditionalRender>
          </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default InvestRevamp
