import { useEffect, useState } from 'react'
import { PageLoading, Button } from 'components'
import { images } from 'assets'
import { useHistory, useLocation } from 'react-router-dom'
import {
  getInvestorStatus,
  saveAccreditedInvestorStatusChoice,
  saveSelfAccreditation
} from 'slices/investorStatusSlice'
import { useDispatch } from 'react-redux'
import { Mixpanel, useLDFlags } from 'utils'

const SelfAttestationUS = ({ withProgressBar = false, updateProgressBar }) => {
  const { selfAttestUs } = useLDFlags('selfAttestUs')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [pageLoading, setPageLoading] = useState(true)
  useEffect(() => {
    if (withProgressBar) {
      setPageLoading(false)
      return
    }
    if (selfAttestUs) {
      getInvestorStatusPage()
    } else if (selfAttestUs !== undefined && !selfAttestUs) {
      history.push('/profile')
    }
  }, [selfAttestUs, withProgressBar])

  const getInvestorStatusPage = async () => {
    const { meta, payload } = await dispatch(getInvestorStatus())
    if (meta.requestStatus === 'fulfilled') {
      const data = payload
      if (data?.accreditedStatus === 'ACCREDITED') {
        history.push('/products')
      }
      if (!data?.hasKyc || !data?.canAttestAsAccreditation) {
        history.push('/profile')
      } else {
        Mixpanel.track('View Self Attestation Page')
      }
    }
    setPageLoading(false)
  }

  const handleSubmitSelfAccredited = async () => {
    setPageLoading(true)
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': 'United States',
      'Accreditation Status': 'Self Accredited'
    })
    await dispatch(saveAccreditedInvestorStatusChoice({ choice: 'PROFESSIONAL_CLIENT', subChoice: 'Self Attestation USA' }))
    setPageLoading(false)
    if (withProgressBar) {
      updateProgressBar('selfAttestationSubmitSuccess')
    }
    history.replace(location.pathname, { type: 'selfAccredited' })
  }
  const handleSubmitRetail = async () => {
    setPageLoading(true)
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto',
      'Accredited Country': 'United States',
      'Accreditation Status': 'Not Accredited'
    })
    await dispatch(saveSelfAccreditation({ selfAccreditation: 'NOT_ACCREDITED' }))
    setPageLoading(false)
    if (withProgressBar) {
      updateProgressBar('selfAttestationSubmitSuccess')
    }
    history.replace(location.pathname, { type: 'notAccredited' })
  }

  const handleExploreButton = () => {
    /* If loaded in react native webview, post message
    so webview can remove required elements from DOM */
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      history.push('/products')
    }
  }

  if (location?.state?.type) {
    const type = location.state.type
    const copy = type === 'selfAccredited' ? 'You now have full access to companies revolutionizing AI, digital assets, and space technology.' : 'You now have access to a select number of companies.'
    return (
      <div className='page-container investor-status-container'>
        <div className='inner-container'>
          <div className='accreditation-verified self-attest'>
            <img src={images.acc_verified} alt='Accreditation Verified' aria-label='accreditation-verified'/>
            <h1 className='accreditation-title centered'>You&apos;re ready to invest!</h1>
            <div className='b_18_regular accreditation-verified-copy'>{copy}</div>
            <Button onClick={handleExploreButton} customClass='full-width'>Explore Investments</Button>
          </div>
        </div>
      </div>
    )
  }
  if (pageLoading) {
    return (
      <PageLoading />
    )
  }

  const mainComponent = (
    <>
      <h1 className='heading_7'>Are you an Accredited Investor?</h1>
      <div className='verify-acc b_18_regular gray5'>You may get access to additional opportunities by letting us know if you are accredited.</div>
      <div className='acc-nav-item self-attest' onClick={handleSubmitSelfAccredited} role='button' tabIndex={0} aria-label='Yes'>
        <div className='acc-nav-copy'>
          <div className='b_18_semibold gray5'>Yes</div>
        </div>
        <img src={images.arrow_forward_ios} alt='Yes, I am an accredited investor' className='acc-nav-arrow' />
      </div>
      <div className='acc-nav-item self-attest' onClick={handleSubmitRetail} role='button' tabIndex={1} aria-label='No'>
        <div className='acc-nav-copy'>
          <div className='b_18_semibold gray5'>No</div>
        </div>
        <img src={images.arrow_forward_ios} alt='No, I am not an accredited investor' className='acc-nav-arrow' />
      </div>
      <div className='acc-terms-box'>
        <div className='my-6 b_18_semibold'>You are an Accredited Investor if you have:</div>
        <div className='my-6'>An earned income of more than $200,000 (or $300,000 together with a spouse) in each of the last two years</div>
        <div className='my-6'>A net worth over $1 million (excluding the value of a primary residence)</div>
        <div className='my-6'>A Series 7, Series 65 or Series 82 financial security license</div>
      </div>
    </>
  )
  if (withProgressBar) {
    return mainComponent
  }
  return (
    <div className='page-container investor-status-container'>
      <div className='inner-container'>
        {mainComponent}
      </div>
    </div>
  )
}

export default SelfAttestationUS
