import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel, getDomain } from 'utils'

const FooterLinks = ({ isOnAppDomain }) => {
  const { isSignedIn, featureFlags } = useSelector((state) => state.userSlice)
  const { CrsCheckEnabled } = featureFlags || {}
  const supportItem = [
    { route: '/faq', name: 'FAQs', cms: true, label: 'Read FAQs' },
    { route: '/contact', name: 'Contact Us', cms: false, label: 'Contact Us' },
    { route: '/signup', name: 'Get Started', cms: false, label: 'Sign up with Linqto' }
  ]
  const forInvestorItem = [
    { route: '/products', name: 'Invest', cms: !isSignedIn, label: 'Invest with Linqto' },
    { route: '/market-insights', name: 'Insights', cms: true, label: 'Read Market Insights' },
    { route: '/blog', name: 'Blog', cms: true, label: 'Read our Blog' }
  ]
  const companyItem = [
    { route: '/about', name: 'About Us', cms: true ,label: 'Learn about Linqto' },
    { route: '/team', name: 'Team', cms: true, label: 'Meet Our Team' },
    { route: '/partners', name: 'Partners', cms: true, label: 'Meet Our Partners' },
    { route: '/terms-of-use', name: 'Terms of Use', cms: true, label: 'Read Terms of Use' },
    { route: '/crs', name: 'Form CRS', cms: true, label: 'Read Form CRS' },
    { route: '/privacy', name: 'Privacy', cms: true, label: 'Read Privacy Policy' },
    { route: '/press', name: 'Press', cms: true, label: 'Read Press Releases' },
    { route: '/careers', name: 'Careers', cms: true, label: 'Join Our Careers' }
  ]
  // when CrsCheckEnabled is false, remove Form CRS from the list. If CrsCheckEnabled is true, show All
  const filteredCompanyItem = companyItem.filter(item => item.route !== '/crs' || CrsCheckEnabled)

  const forEquityHolderItem = [{ route: '/sell', name: 'Sell to Linqto', cms: true, label: 'Sell Equity to Linqto' }]
  const trackLink = (link) => {
    Mixpanel.track('Click Footer Link', { 'Footer Link': link })
    if (link === 'Invest') {
      localStorage?.removeItem('investFeedScroll')
    }
  }

  const renderLinkItem = (route, name, cms, label) => {
    if (isSignedIn && name === 'Sign up') {
      return
    }
    if (cms && isOnAppDomain) {
      return (
        <div className='item' key={name}>
          <a
            role='button'
            tabIndex={0}
            key={`footer-${name}`}
            href={getDomain(route, cms)}
            onClick={() => trackLink(name)}
            className='b_18_regular footer-link'
            aria-label={label}
          >
            {name}
          </a>
        </div>
      )
    } else {
      return (
        <div className='item' key={name}>
          <Link
            to={route}
            className='b_18_regular footer-link'
            onClick={() => trackLink(name)}
            role='button'
            tabIndex='0'
            aria-label={label}
          >
            {name}
          </Link>
        </div>
      )
    }
  }
  return (
    <div className='footer-links'>
      <div className='nav-group'>
        <div className='navs' role='navigation' aria-labelledby='support-title'>
          <div className='nav-title' id='support-title'>Support</div>
          {supportItem.map((i) => renderLinkItem(i.route, i.name, i.cms, i.label))}
        </div>
        <div className='navs' aria-labelledby='investors-title'>
          <div id='investors-title'  className='nav-title'>For Investors</div>
          {forInvestorItem.map((i) =>
            renderLinkItem(i.route, i.name, i.cms, i.label)
          )}
        </div>
      </div>
      <div className='nav-group'>
        <div className='navs' role='navigation' aria-labelledby='company-title'>
          <div className='nav-title' id='company-title'>Company</div>
          {filteredCompanyItem.map((i) => renderLinkItem(i.route, i.name, i.cms, i.label))}
        </div>
        <div className='navs' role='navigation' aria-labelledby='equity-holders-title'>
          <div className='nav-title' id='equity-holders-title'>For Equity Holders</div>
          {forEquityHolderItem.map((i) =>
            renderLinkItem(i.route, i.name, i.cms, i.label)
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterLinks
