import { getDomain } from 'utils'
import { Link } from 'react-router-dom'
import { images } from 'assets'

const FooterLogo = ({ isOnAppDomain }) => {
  return (
    <div className='footer-logo'>
      {isOnAppDomain ? <a
        href={getDomain('/', true)}
        aria-label='Go to Linqto Homepage'
      >
        <img className='logo' alt='Linqto Logo - Your Access to Private Investment' src={images['footer-logo']} />
      </a> : <Link to='/'  aria-label='Go to Linqto Homepage'>
        <img
          className='logo'
          alt='Linqto Logo - Your Access to Private Investment'
          src={images['footer-logo']}
        ></img>
      </Link>}
      <div className='heading_3 footer-heading mt-[30px]' id='footer-heading'>Your Access to Private Investment</div>
    </div>
  )
}

export default FooterLogo
