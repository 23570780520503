import _ from 'lodash'
import { usResidencyStatus } from 'components/data'

const parseProfile = (data) => {
  const u = data.userProfile || {}
  const tc = data.trustedContact || {}

  const primaryAdress = u.addresses.filter(function (a) {
    return a.type === 'ADDRESS'
  })[0] || []

  const mailingAddress = u.addresses.filter(function (a) {
    return a.type === 'MAILING'
  })[0] || []

  const primary = _.pick(primaryAdress, ['street1', 'street2', 'city', 'state', 'country', 'zip'])
  const mailing = _.pick(mailingAddress, ['street1', 'street2', 'city', 'state', 'country', 'zip'])

  return {
    email: u.email ? u.email : '',
    firstName: u.firstName ? u.firstName : '',
    lastName: u.lastName ? u.lastName : '',
    phone: u.phone ? u.phone : '',
    taxId: u.taxId ? u.taxId : '',
    pStreet1: primaryAdress.street1 ? primaryAdress.street1 : '',
    pStreet2: primaryAdress.street2 ? primaryAdress.street2 : '',
    pCity: primaryAdress.city ? primaryAdress.city : '',
    pState: primaryAdress.state ? primaryAdress.state : '',
    pCountry: primaryAdress.country ? primaryAdress.country : '',
    pZip: primaryAdress.zip ? primaryAdress.zip : '',
    mStreet1: mailingAddress.street1 ? mailingAddress.street1 : '',
    mStreet2: mailingAddress.street2 ? mailingAddress.street2 : '',
    mCity: mailingAddress.city ? mailingAddress.city : '',
    mState: mailingAddress.state ? mailingAddress.state : '',
    mCountry: mailingAddress.country ? mailingAddress.country : '',
    mZip: mailingAddress.zip ? mailingAddress.zip : '',
    sameAddress: !_.isEqual(primary, mailing) || false,
    type: (data.entity && data.entity.isComplete) ? data.entity.name : '',
    hasEntity: data.entity,
    usResidendcyStatus: usResidencyStatus[u?.usResidendcyStatus] || 'NOT_REQUIRED',
    citizenshipCountry: u.citizenshipCountry ? u.citizenshipCountry : '',
    foreignTaxId: u.foreignTaxId ? u.foreignTaxId : '',
    trustedContactFirstName: tc?.firstName || '',
    trustedContactLastName: tc?.lastName || '',
    trustedContactPhone: tc?.phone || '',
    trustedContactRelationship: tc?.relationship || '',
    trustedContactStreet1: tc?.address?.street1 || '',
    trustedContactStreet2: tc?.address?.street2 || '',
    trustedContactCity: tc?.address?.city || '',
    trustedContactState: tc?.address?.state || '',
    trustedContactCountry: tc?.address?.country || '',
    trustedContactZip: tc?.address?.zip || '',
    occupation: u?.occupation,
    employerName: u?.employerName,
    sectors: u?.sectors?.map(s => ({ value: s.sectorId, label: s.name })) || [],
    annualIncome: u?.annualIncome,
    networthExcludingResidence: u?.networthExcludingResidence,
    capitalAllocation: u?.capitalAllocation,
    acknowledgedRiskAt: u?.acknowledgedRiskAt,
    acknowledgedCrsAt: u?.acknowledgedCrsAt,
    isRetailUser: u?.isRetailUser
  }
}

export default parseProfile
