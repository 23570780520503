import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'slices/userSlice'
import NavHeader from './partials/NavHeader'
import MobileNav from './partials/MobileNav'
import WebNav from './partials/WebNav'
import { Mixpanel, useLDFlags } from 'utils'
import { Button } from 'components'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const Nav = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { referMenuItem, hubspotLearn } = useLDFlags(['referMenuItem', 'hubspotLearn'])

  const { isSignedIn, isUserReturned } = useSelector((state) => state.userSlice)
  const [showDropdown, setShowDropdown] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(false)

  const logOut = async () => {
    Mixpanel.track('Click Sign out')
    await dispatch(signOut())
    const host = window.location.hostname
    // if domain is www.linqto.com, beta.linqto.com, alpha.linqto.com, dev.linqto.com
    if (!host.includes('app')) {
      window.location = '/'
      // if domain is app.linqto.com => We need to test on this domain
    } else if (host === 'app.linqto.com') {
      window.location = 'https://www.linqto.com/'
      // if domain is app.beta.linqto.com, app.alpha.linqto.com, app.dev.linqto.com => We need to test on this domain
    } else {
      const hostname = host.substring(4, window.location.hostname.length)
      window.location = `https://${hostname}`
    }
  }

  const goToHelpCenter = () => {
    Mixpanel.track('Click Help Center')
    window.open('https://help.linqto.com/', '_blank')
  }

  const goToRewardsPage = () => {
    history.push('/rewards')
    Mixpanel.track('Click Get $1000 in Main Nav')
  }
  const accountNavItems = [
    { route: '/portfolio', name: 'My Portfolio', tag: 'portfolio', show: false, domain: 'user', showOnMobile: true },
    { route: '/linqto-wallet', name: 'Wallet', tag: 'wallet', show: false, domain: 'user', showOnMobile: true },
    { route: '/documents', name: 'Documents', tag: 'documents', show: false, domain: 'user', showOnMobile: true },
    { route: '/profile', name: 'Investor Profile', tag: 'profile', show: false, domain: 'user', showOnMobile: true },
    { route: '/settings', name: 'Settings', tag: 'settings', show: false, domain: 'user', showOnMobile: true },
    { route: '/rewards', name: 'Linqto Bucks', tag: 'rewards', show: true, domain: 'user', showOnMobile: true, secondaryItem: referMenuItem ? 'Get $1,000' : null },
    { route: '/', name: 'Sign out', tag: 'signout', show: false, domain: 'user', showOnMobile: false, action: logOut }
  ]

  const discoverNavItems = [
    { route: '/about', name: 'About Linqto', tag: 'about', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/blog', name: 'Blog', tag: 'blog', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/faq', name: 'FAQs', tag: 'faq', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/market-insights/', name: 'Market Insights', tag: 'marketInsights', show: false, domain: 'global', showOnMobile: true, cms: true },
    { route: '/unicorn-news/', name: 'Unicorn News', tag: 'unicornNews', show: false, domain: 'global', showOnMobile: true, cms: true }
  ]

  const LinqtoBucksBtn = () => {
    if (!referMenuItem || !isSignedIn) return null
    return <Button mode='secondary' ariaLabel='Get $1,000' customClass='nav-rewards' onClick={goToRewardsPage} data-testid='get1000Btn'><img src={images.rewards_gifts} alt='Get $1,000' />Get $1,000</Button>
  }

  if (!isSignedIn) {
    discoverNavItems.shift()
  }

  if (hubspotLearn) {
    discoverNavItems.push({ route: '/help', name: 'Help Center', tag: 'linqtoHelp', show: false, domain: 'global', showOnMobile: true, action: goToHelpCenter })
  }
  // NOTE: array of nav items
  const mainNavItems = [
    { route: '/products', name: 'Invest', tag: 'invest', show: false, domain: 'global', showOnMobile: true, cms: !isSignedIn },
    { route: '/how-it-works', name: 'How It Works', tag: 'how-it-works', show: false, domain: 'public', showOnMobile: true, cms: true },
    { route: '/', name: 'Discover', tag: 'discover', show: false, domain: 'global', showOnMobile: false, submenu: discoverNavItems },
    { route: '/about', name: 'About', tag: 'about', show: false, domain: 'public', showOnMobile: false, cms: true },
    { route: '/signup', name: 'Get Started', tag: 'signup', show: false, domain: 'public', showOnMobile: true, style: 'button', cms: false },
    { route: '/signin', name: 'Sign In', tag: 'sign-in', show: false, domain: 'public', showOnMobile: true, cms: false },
    { route: '/', name: 'Account', tag: 'account', show: false, domain: 'user', showOnMobile: false, submenu: accountNavItems }
  ]

  const filterNavByStatus = (nav) => {
    const newNav = isSignedIn
      ? nav.filter((i) => i.domain === 'user' || i.domain === 'global')
      : nav.filter((i) => i.domain === 'public' || i.domain === 'global')
    return newNav
  }

  return (
    <div id='header-div' className='nav-container' data-testid='header-div'>
      <NavHeader
        setToggleStatus={setToggleStatus}
        toggleStatus={toggleStatus}
      />
      {isUserReturned &&
      <>
        <WebNav
          setToggleStatus={setToggleStatus}
          toggleStatus={toggleStatus}
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
          items={filterNavByStatus(mainNavItems)}
          filterNavByStatus={filterNavByStatus}
          customNavItem={LinqtoBucksBtn}
        />
        {toggleStatus && (
          <MobileNav
            setToggleStatus={setToggleStatus}
            toggleStatus={toggleStatus}
            items={filterNavByStatus(mainNavItems)}
            filterNavByStatus={filterNavByStatus}
            customNavItem={LinqtoBucksBtn}
          />
        )}
      </>}
    </div>
  )
}

export default Nav
