import { formatDecimal, transformPromoFlag } from 'utils'
import { Button } from 'components'
import PropTypes from 'prop-types'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const tagStyle = ({type}) => {
  switch(type) {
    case 'TOP_SELLER': return 'text-[#3E1C96] border-[#9E77ED]'
    case 'COMING_SOON': return 'text-[#1849A9] border-[#53B1FD]'
    case 'FULLY_SUBSCRIBED': return 'border-gray2 text-gray3'
    default: return 'border-gray2 text-gray3'
  }
}

const renderThumbnailItem = ({listType, item, hideTag}) => {
  switch(listType) {
    case 'products': 
      return (
      <div className='w-full'>
        <img src={item?.alternateLogoUrl || item?.iconUrl} alt={`${item?.name} logo`} className='w-full h-32 rounded-lg'/>
        {item?.promoFlag !== 'NONE'  && item?.promoFlag !== hideTag && 
        <span className={`absolute border rounded-[4px] top-6 left-6 bg-white ${tagStyle(item?.promoFlag)} text-xs px-1 uppercase`}>{transformPromoFlag(item?.promoFlag)}</span> }
      </div>)
    
    case 'bundles': 
    return (
      <div className='flex ml-[6px] overflow-hidden'>
          {item?.companies && item?.companies.length > 0 ? item?.companies?.map(({ iconUrl, name }, index) => (
            <img key={index} className='w-12 h-12 rounded-[50%] ml-[-6px]' src={iconUrl} alt={name} />
          )) : null}
    </div>
    )
    default: return '<div />'
  }
}

const ThumbnailItem = ({ listType, item, hideTag ='', onItemClick,index, section }) => (
  <Button data-testid={`thumb-${listType}-${index}`} className='flex flex-col border rounded-2xl border-[#DFDFDF] p-4 relative w-full md:w-[23%] overflow-hidden items-start text-left' onClick = {() => onItemClick(item, section) } ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    {renderThumbnailItem({listType, item, hideTag})}
    <div className='w-full mt-2 overflow-hidden text-lg font-CodecColdBold whitespace-nowrap overflow-ellipsis'>{item?.name || item?.title}</div>
    <div className='w-full overflow-hidden text-sm font-CodecColdRegular whitespace-nowrap overflow-ellipsis'>{item?.vertical || item?.companies?.map(obj => obj.name).join(' • ')}</div>
    <div className='mt-2 text-left'><div className='text-xs text-gray3 font-CodecColdRegular'>{listType ==='bundles'? 'Bundle': 'Share'} Price</div>{(item?.sharePrice || item?.price) ? <div className='font-CodecColdExtraBold'>{formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}</div> : <div className='font-CodecColdExtraBold text-gray3'>N/A</div>}</div>
  </Button>
)

const ListItem = ({ item, onItemClick, index, section }) => (
  <Button data-testid={`list-products-${index}`} className='flex border rounded-2xl border-[#DFDFDF] p-4 gap-4 w-full md:w-[48.7%] items-center' onClick = {() => onItemClick(item, section)} ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    <img src={item?.iconUrl} alt={`${item?.name} logo`} className='w-[48px] h-[48px] rounded-[50%]' />
    <div className='flex flex-col items-start'>
      <div className='font-CodecColdBold'>{item?.name}</div>
      <div className='text-sm font-CodecColdRegular'>{item?.vertical}</div>
    </div>
    <div className='ml-auto text-right'><div className='text-xs text-gray3 font-CodecColdRegular'>Share Price</div>{item?.sharePrice ? <div className='font-CodecColdExtraBold'>{formatDecimal(item?.sharePrice)}</div> : <div className='font-CodecColdExtraBold text-gray3'>N/A</div> }</div>
  </Button>
)

const InvestSection = ({ title, list = [], listType = 'products', listStyle='thumbnail', hideTag='', onItemClick }) => {
  return (
    <div className='mb-14'>
      {title !== '' && <h2 className='mb-4 text-2xl font-CodecColdBold'>{title}</h2>}
      <div className={`flex flex-wrap min-w-full ${listStyle === 'thumbnail' ? 'gap-7' : 'gap-x-7 gap-y-5'}`}>
        {list?.length > 0 && list.map((item, i) =>
        listStyle === 'thumbnail' ? <ThumbnailItem key={`list_${i}`} listType={listType} item={item} hideTag={hideTag} onItemClick={onItemClick} index={i} section={title} /> :   <ListItem key={`list_${i}`} item={item} onItemClick={onItemClick} index={i} section={title}/>
        )}
      </div>
    </div>)
}

InvestSection.propTypes = {
  listType: PropTypes.oneOf(['products', 'bundles']),
  listStyle:  PropTypes.oneOf(['thumbnail', 'list']),
  list:  PropTypes.array,
  title: PropTypes.string,
  hideTag: PropTypes.string,
  onItemClick: PropTypes.func
}

export default InvestSection

